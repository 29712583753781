// vue3 全局注册组件
// import amap from 'vue-amap';// 这个引入的最好放在引入的vue上面
import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap';
import {
    createApp
} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@vuemap/vue-amap/dist/style.css'
import App from './App.vue'
import router from './router'

// 新增代码：引入特定组件及样式
import {
    Button, Form, Input
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/lib/button/style/css'
import 'ant-design-vue/lib/form/style/css'
import 'ant-design-vue/lib/input/style/css'
import '../src/assets/css/reset.css'

const app = createApp(App)
app.use(ElementPlus)
app.use(Button);
app.use(Form);
app.use(Input);
app.use(VueAMap);
app.use(router);
app.mount('#app');
initAMapApiLoader({
    key: 'dd4cc5f831b67cde767de9a65f983650',  // 你的key
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation','AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],//应用功能项
    v: '1.4.4', //版本
    uiVersion: '1.0' //ui版本
  })