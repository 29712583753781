<template>
  <!-- 试用产品 -->
  <div class="ontrialDialog" v-if="visible">
    <div class="ontrial_mask"></div>
    <div class="ontrial_main">
      <div class="close_btn" @click="closeOntrialDialog">
        <img src="../assets/img/close_icon.png" alt="" />
      </div>
      <h3>了解试用优数相关产品</h3>
      <p>请填写相关信息，我们将会及时与您联系！</p>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        @finish="onSubmit"
      >
        <a-form-item name="EnterpriseName">
          <a-input
            v-model:value="formState.EnterpriseName"
            placeholder="您的企业名称"
          />
        </a-form-item>
        <a-form-item name="userName">
          <a-input v-model:value="formState.userName" placeholder="您的姓名" />
        </a-form-item>
        <a-form-item name="userPhone">
          <a-input
            v-model:value="formState.userPhone"
            placeholder="您的手机号码"
          />
        </a-form-item>
        <a-button
          block
          type="primary"
          html-type="submit"
          style="margin-top: 20px"
          >提交</a-button
        >
      </a-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { Form } from 'ant-design-vue';
const useForm = Form.useForm;
export default defineComponent({
  props: ["visible"],
  setup(props, context) {
    const formRef = ref();
    const formState = reactive({
      EnterpriseName: "",
      userName: "",
      userPhone: "",
    });

    let checkUserPhone = async (_rule, value) => {
      let Exp = /^1[3-9]\d{9}$/;
      console.log(Exp.test(value));
      if (!value) {
        return Promise.reject("您的手机号不能为空");
      } else if (!Exp.test(value)) {
        return Promise.reject("手机号格式错误");
      } else {
        return Promise.resolve();
      }
    };

    let validateEnterpriseName = async (_rule, value) => {
      console.log(value);
      if (value === "") {
        return Promise.reject("您的企业名称不能为空");
      } else {
        return Promise.resolve();
      }
    };

    let validateUserName = async (_rule, value) => {
      if (value === "") {
        return Promise.reject("您的姓名不能为空");
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      EnterpriseName: [
        {
          required: true,
          validator: validateEnterpriseName,
          trigger: "blur",
        },
      ],
      userName: [
        {
          validator: validateUserName,
          trigger: "blur",
        },
      ],
      userPhone: [
        {
          validator: checkUserPhone,
          trigger: "blur",
        },
      ],
    };

    const onSubmit = () => {
      context.emit("submit", formState);
      context.emit("updateVisible", false);
      resetFields();
    };

    const closeOntrialDialog = () => {
      context.emit("updateVisible", false);
      resetFields();
    };

    const {
      resetFields
    } = useForm(formState);

    return {
      formState,
      formRef,
      rules,
      onSubmit,
      closeOntrialDialog,
      resetFields
    };
  },
});
</script>

<style scoped>
.ontrialDialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 101;
}
.ontrial_mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.ontrial_main {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  margin: auto;
  width: 510px;
  height: 500px;
  padding: 60px;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
}
.ontrial_main h3 {
  font-size: 32px;
  color: #333333;
  margin-bottom: 20px;
}
.ontrial_main p {
  font-size: 16px;
  color: #333333;
  margin-bottom: 40px;
}

input:-webkit-autofill {
  border: 1px solid #ccc !important;
}

.ant-btn-primary {
  background: #3f72fd !important;
  border-color: #3f72fd !important;
  height: 46px;
}
.ant-input {
  height: 46px;
  line-height: 46px;
}

.close_btn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.close_btn img {
  width: 20px;
  height: 20px;
}

:deep(.ant-form-item-explain) {
  text-align: left !important;
}
:deep(input:-webkit-autofill:hover) {
  border: 1px solid #ccc !important;
}
</style>