<template>
  <div>
    <div class="fixed-dialog">
      <div class="fixed-cont">
        <ul>
         <!-- <li @mouseenter="hoverShowDialog" @mouseleave="leaveShowDialog">
            <img src="@/assets/img/fixed_icon_01.png" alt="" />
            <p>商务合作</p>
          </li> -->
          <li @click="ontrial">
            <img src="@/assets/img/fixed_icon_02.png" alt="" />
            <p>产品试用</p>
          </li>
          <li @mouseenter="hoverShowMask" @mouseleave="leaveShowMask">
            <img src="@/assets/img/fixed_icon_03.png" alt="" />
            <p>小程序</p>
          </li>
        </ul>
        <div v-if="isShowDialog" class="business-cooperation">
          <p>商务合作</p>
          <p>19201144101</p>
        </div>
        <div v-if="isShowMask" class="mask-cooperation">
          <img src="@/assets/img/anyipin_xcx.jpg" alt="" />
          <p>安易聘小程序</p>
        </div>
      </div>
      <div class="goto_Back" v-if="scrollTop > moreDivOffsetTop" @click="gotop">
        <ul>
          <li>
            <img src="@/assets/img/fixed_icon_05.png" alt="" />
            <p>返回顶部</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fixedDialog",
  props: ["scrollTop", "moreDivOffsetTop"],
  data() {
    return {
      tryDialog: false,
      isShowDialog: false,
      isShowMask: false,
    };
  },
  methods: {
    hoverShowDialog() {
      this.isShowDialog = true;
    },
    leaveShowDialog() {
      this.isShowDialog = false;
    },
    hoverShowMask() {
      this.isShowMask = true;
    },
    leaveShowMask() {
      this.isShowMask = false;
    },
    gotop() {
      let top = document.documentElement.scrollTop; //获取点击时页面的滚动条纵坐标位置
      const timeTop = setInterval(() => {
        document.documentElement.scrollTop = top -= 50; //一次减50往上滑动
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 5); //定时调用函数使其更顺滑
    },
    ontrial() {
      this.$emit('trilFome', true)
    }
  },
};
</script>

<style scoped>
.fixed-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.fixed-dialog {
  position: fixed;
  right: 3%;
  bottom: 14%;
  height: 360px;
}
.fixed-cont {
  box-shadow: 0 0 2px 2px #f2f2f2;
  border-radius: 75px;
  padding: 5px 15px;
  background: #fff;
  margin-bottom: 20px;
}
.goto_Back {
  box-shadow: 0 0 2px 2px #f2f2f2;
  border-radius: 75px;
  padding: 5px 15px;
  background: #fff;
}
.fixed-cont ul li {
  border-bottom: 1px solid #e4e4e4;
  padding: 20px 0px;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.fixed-cont ul li:last-child {
  border-bottom: none;
}
.fixed-cont ul li img {
  width: 20px;
  height: 20px;
}
.fixed-cont ul li p {
  padding-top: 10px;
  font-size: 12px;
}

.goto_Back ul li {
  padding: 12px 0px;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.goto_Back ul li:last-child {
  border-bottom: none;
}
.goto_Back ul li img {
  width: 20px;
  height: 20px;
}
.goto_Back ul li p {
  padding-top: 10px;
  font-size: 12px;
}

.business-cooperation {
  width: 150px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0 0 8px 3px #e9e9e9;
  position: absolute;
  right: 115%;
  top: 5%;
  border-radius: 5px;
  padding: 5px 20px;
  background: #fff;
}
.business-cooperation p {
  line-height: 35px;
}
.business-cooperation p:nth-child(2) {
  font-weight: bold;
  color: #3f72fd;
}

.mask-cooperation {
  width: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 8px 3px #e9e9e9;
  position: absolute;
  right: 115%;
  bottom: 5%;
  border-radius: 5px;
  padding: 5px 20px;
  background: #fff;
}
.mask-cooperation p {
  line-height: 35px;
  font-size: 12px;
}

.mask-cooperation img {
  width: 100px;
  height: 100px;
}
</style>