<template>
  <div class="header">
    <div class="header-cont c-w1200">
		<div class="headleft">
      <router-link to="/">
        <img v-if="start==0 || !start" alt="Vue logo" src="@/assets/img/ushuy4.png" />
        <img v-if="start==1" alt="Vue logo" src="@/assets/img/admin_new_logo.png" />
      </router-link>
      <ul>
        <!-- <li>
          <router-link to="/#" @click.native="toggleActive(0)" :class="{ active: isActive(0) }">首页</router-link>
      
        </li>
        <li>
          <router-link to="/#more" @click.native="toggleActive(1)" :class="{ active: isActive(1) }">产品服务</router-link>
      
        </li>
        <li>
          <router-link to="/#customerCon" @click.native="toggleActive(2)" :class="{ active: isActive(2) }">客户案例</router-link>
        </li>
        <li>
          <router-link to="/#aboutCon" @click.native="toggleActive(3)" :class="{ active: isActive(3) }">关于我们</router-link>
        </li>
        <li>
          <router-link to="/#aboutMyCon" @click.native="toggleActive(4)" :class="{ active: isActive(4) }">联系我们</router-link>
        </li> -->
		<li v-for="(item, index) in navItems" :key="index">  
		        <router-link  
		          :to="item.url"  
		          @click.native="toggleActive(index)"  
		          :class="{ active: isActive(index) }"  
		        >  
		          {{ item.text }}  
		        </router-link>  
		      </li>
        <!-- <li>
          <a-button @click="ontrial">免费试用<RightCircleOutlined size="16" /></a-button>
        </li> -->
      </ul>
	  </div>
	  <div class="headright">
		  <!-- <div class="denfglu" @click="dengluopen"><p>用户登录</p></div> -->
		  <div class="zhuce" @click="zhuceopen"><p>我要招聘</p></div>
	  </div>
    </div>
  </div>
</template>

<script>
// import { RightCircleOutlined } from "@ant-design/icons-vue";
export default {
  name: "Headers",
  // components: {
  //   RightCircleOutlined,
  props:{
    start:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
		activeIndex:0,
		navItems:[
			{ text: '首页', url: '/#home' },  
        { text: '产品服务', url: '/#more' },
		{ text: '客户案例', url: '/#customerCon' },
		{ text: '关于我们', url: '/#aboutCon' },
		{ text: '联系我们', url: '/#aboutMyCon' },
		],
		sections: [  
		        // 这些值应该与您的页面锚点相对应  
		        { id: 'home', offset: 0 }, // 假设首页不需要滚动  
		        { id: 'more', offset: 100 },
				{ id: 'customerCon', offset: 5700 }, 
				{ id: 'aboutCon', offset: 1700 }, 
				{ id: 'aboutMyCon', offset: 2000 }, 
				 // 假设 'more' 部分在页面下方 100px  
		        // ... 其他部分  
		      ], 
	}
  },
    mounted() {  
      window.addEventListener('scroll', this.updateActiveIndex);  
	  // let moreDivOffsetTop = document.getElementById("more").offsetTop;
	  // this.moreDivOffsetTop = moreDivOffsetTop;
	  // window.addEventListener("scroll", this.handleScroll);
    },  
    beforeDestroy() {  
      window.removeEventListener('scroll', this.updateActiveIndex);  
    },
  methods: {
	  updateActiveIndex() {
		  // console.log("滚动")
	       // 根据滚动位置更新 activeIndex  
	       const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;  
		   // console.log("滚动scrollTop==",scrollTop)
	     //   for (let i = 0; i < this.sections.length; i++) {  
			   // console.log("this.sections[i].offset==",this.sections[i])
			   // console.log("this.sections[i]1111t==",i)
	     //     if (scrollTop > this.sections[i].offset) {  
	     //       this.activeIndex = i;  
	     //       break;  
	     //     }  
	     //   }  
		 if(scrollTop <= 1630){
			 this.activeIndex = 0;
		 }else if(scrollTop >= 1630 && scrollTop <= 3804){
			 this.activeIndex = 1;
		 }else if(scrollTop >= 3804 && scrollTop <= 5304){
			 this.activeIndex = 2;
		 }else if(scrollTop >= 5304 && scrollTop <= 6681){
			 this.activeIndex = 3;
		 }else{
			 this.activeIndex = 4;
		 }
	     },  
    ontrial() {
      this.$emit('trilFome', true)
    },
    aaaa(){
      console.log(11111);
    },
	dengluopen(){
		console.log(333);
	},
	zhuceopen(){
		let local = window.location.href
		if(local.includes('ushuy')){
			console.log("s")
			window.location.href = "https://www.51abep.com/login/"
		}else{
			console.log("bus")
			window.location.href = "https://pre-www.ushuy.com/login/"
		}
	},
	toggleActive(index) {  
		console.log("index=",index)
	      this.activeIndex = index;  
		  
	    },  
	    isActive(index) {  
	      return this.activeIndex === index;  
	    },
	// window.location.href="https://pre-www.ushuy.com/login/"
	// https://pre-www.ushuy.com/register/
  }
};
</script>

<style scoped>
@import url("../assets/css/common.css");
.active {  
  border-bottom:4px solid #0060F5; /* 示例背景色 */  
}  

.header {
 /* position: absolute;
  left: 0;
  right: 0; */
  position: fixed;
  top:0;
  left:0;
  width:100%;
  z-index: 100;
  height:98px;
/* background: rgba(0,0,0,0.6); */

background: rgba(255,255,255,1);
display:flex;
justify-content:flex-start;
align-items:center;
}
.headleft{
	display:flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height:100%;
}
.headright{
	display:flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
.denfglu{
	width: 86px;
	height: 33.88px;
display:flex;
	flex-direction: row;
	justify-content:center;
	align-items: center;	
	border-radius: 8px;
	background: #1F57F4;
	margin-right:24px;
	cursor: pointer;
}
.denfglu p{
	font-size: 14px;
	color:#ffffff;
}
.zhuce{
	width: 118px;
	height: 48px;
display:flex;
	flex-direction: row;
	justify-content:center;
	align-items: center;	
	border-radius: 8px;
	background: #1F57F4;
	cursor: pointer;
}
.zhuce p{
	font-size: 20px;
	color:#ffffff;
}
.header-cont {
  display: flex;
  align-items: center;
  justify-content:space-between;
  height:100%;
  /* justify-content: flex-start; */
  /* padding-top: 20px; */
}
.header-cont img {
  /* height: 68px;
  width:162px; */
  height: 56px;
  width:161px;
}
.header-cont ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left:50px;
  height:100%;
}
.header-cont ul li {
  list-style: none;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  height:100%;
  width:140px;
  display: flex;
  align-items: center;
  justify-content:center;
  /* padding: 20px 40px; */
  /* padding:0 40px; */
}
.header-cont ul li:last-child {
  padding-right: 0 !important;
}
.header-cont ul li a {
  color: #191919;
  font-size: 18px;
      height: 100%;
      display: flex;
      align-items: center;
}
.header-cont ul li a.active{
	color: #0060F5;
}
.ant-btn {
  color: #3f72fd !important;
}
</style>
