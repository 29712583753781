<template>
  <div class="section_main">
    <div class="section_cont swiper_cont">
      <div class="c-w1200 swiper_text">
        <div class="swiper_title">数字化+智慧化</div>
        <div class="swiper_title">优数合一助力安保行业提升服务能力</div>
      </div>
    </div>
    <div class="section_cont">
      <div class="c-w1200" v-if="customerId == 1">
        <h3>北京都杰安防科技有限公司</h3>

        <div class="desc_cont">
          <p>
            都杰先后被公安部评定为人力防范保安服务公司“壹级”企业，被商务部国际贸易经济合作研究院评定为AAA企业，是2019年度北京商务服务业创新和国际化品牌百强企业。
          </p>
        </div>
      </div>
      <div class="c-w1200" v-if="customerId == 2">
        <h3>北京京诚京安保安服务有限公司</h3>

        <div class="desc_cont">
          <p>
            北京京诚京安保安服务有限公司，前身是北京振远护卫中心直属支队。公司实施改制于2010年10月份作为北京市公安局首批十佳标杆保安企业之一；专业从事保安、安检服务。
          </p>
        </div>
      </div>
      <div class="c-w1200" v-if="customerId == 3">
        <h3>中城保安（北京）有限公司</h3>

        <div class="desc_cont">
          <p>
            中城保安（北京）有限公司前身系中军军弘安保集团北京分公司。由职业经理人、退转军警和行业专家组成，培养多名保安职业经理人和保安管理师。合作单位300余家，安保人员近1000人。
          </p>
        </div>
      </div>
    </div>

    <fixedDialog></fixedDialog>
  </div>
</template>
  
  <script>
import fixedDialog from "@/components/fixedDialog.vue";
export default {
  name: "customer",
  props: {
    msg: String,
  },
  components: {
    fixedDialog,
  },
  data() {
    return {};
  },
  computed: {
    customerId() {
      console.log(this.$route);
      return this.$route.params.id;
    },
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
@import url("../assets/css/common.css");
/* 基础描述 */
.swiper_cont {
  background: #3f72fd !important;
}
.swiper_text {
  padding-top: 156px;
  padding-bottom: 104px;
}
.swiper_title {
  font-size: 36px;
  color: #fff;
}
.swiper_title:nth-child(1) {
  padding-bottom: 20px;
}
.section_cont h3 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 25px;
}
.section_cont h6 {
  font-size: 16px;
  text-align: center;
}
.desc_cont {
  padding-top: 35px;
  padding-left: 80px;
  padding-right: 80px;
  min-height: 300px;
}
.desc_cont p {
  font-size: 18px;
  text-align: left;
  line-height: 35px;
}
</style>