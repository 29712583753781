<template>
  <div class="footers">
    <div class="c-w1200 footers-cont">
     <!-- <dl>
        <dt v-if="start==0"><img src="/img/ushuy.47ece620.png" alt="" /></dt>
        <dt v-if="start==1"><img src="@/assets/img/admin_new_logo.png" alt="" /></dt>
		<div class="shuxian"></div>
        <dd>
          <p style="white-space: nowrap; color: #fff;">
            Copyright © 2024 Youshu Technology. All Rights Reserved. 优数合一&nbsp;&nbsp;版权所有
          </p>
          <p style="white-space: nowrap; color: #fff;">北京优数合一科技有限公司&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank" style="white-space: nowrap; color: #fff;">京ICP备2021037333号-1</a></p>
        </dd>
      </dl>
      <ul>
        <li>
          <img src="@/assets/img/anyipin_xcx.jpg" alt="" />
          <p style="white-space: nowrap; color: #fff;">安易聘小程序</p>
        </li>
        <li>
          <img src="@/assets/img/public.jpg" alt="" />
          <p style="white-space: nowrap; color: #fff;">优数合一公众号</p>
        </li>
      </ul> -->
	  <div class="logo">
		  <!-- <img class="imgone" v-if="start==0" src="@/assets/img/youshu.png" alt="" /> -->
		  <img class="imgone" v-if="start==0" src="@/assets/img/aypgz.png" alt="" />
		  <img v-if="start==1" src="@/assets/img/admin_new_logo.png" alt="" />
		  <div class="shuxian2"></div>
		  <img class="imgtwo" src="@/assets/img/aypimg.png" alt="" />
	  </div>
	  <div class="shuxian"></div>
	  <div class="lianxi">
		  <div class="lianxi1">
			  <p class="ayptit">联系我们</p>
			  <img src="@/assets/img/anyipin_xcx.jpg" alt="" />
			  <p class="smalltit">微信小程序</p>
			  <p class="smalltit3">扫码体验</p>
		  </div>
		  <div class="lianxi2">
		  			  <img src="@/assets/img/aypgzh.png" alt="" />
		  			  <p class="smalltit">微信公众号</p>
		  			  <p class="smalltit3">扫码体验</p>
		  </div>
		  <div class="lianxi3">
		  	<!-- <p class="smalltit2">电话：4545545455</p> -->
			<p class="smalltit2">邮箱：youshukeji@ushuy.com</p>
			<p class="smalltit2">地址：北京市东城区夕照寺街东<br/>富瑞大厦5层501</p>
		  </div>
	  </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footers",
  props:{
    start:{
      type:Number,
      default:0
    }
  },
};
</script>

<style scoped>
@import url("../assets/css/common.css");
.lianxi{
	display:flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.lianxi1{
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 30px;
}
.lianxi2{
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 30px;
	margin-top: 17px;
}
.lianxi3{
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 30px;
	/* margin-top: 17px; */
}
.lianxi3 p{
	margin-top:10px;
}
.lianxi1 img{
	width:90px;
	height:90px;
	margin-top: 20px;
}
.lianxi2 img{
	width:90px;
	height:90px;
	margin-top: 20px;
}
.ayptit{
	font-size: 18px;
	color: rgba(255,255,255,0.9);
}
.smalltit{
	font-size: 12px;
	color: rgba(255,255,255,0.9);
	margin-top: 20px;
}
.smalltit3{
	font-size: 12px;
	color: rgba(255,255,255,0.9);
	margin-top: 6px;
}
.smalltit2{
	font-size: 14px;
	color: rgba(255,255,255,0.9);
}
.shuxian{
	height:190px;
	width:2px;
	background-color: rgba(255,255,255,0.6);
	margin-left: 90px;
}
.footers {
  background: #003674;
  min-height: 60px;
  height:260px;
}
.footers-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 0 35px 0;
}
.footers-cont dl,
.footers-cont ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footers-cont dl {
  width: 75%;
}
.footers-cont ul {
  width: 20%;
}
.footers-cont dl dt{
  /* height: 60px; */
  width:400px;
  height:164px;
}
.logo{
	display:flex;
	justify-content: center;
	align-items: center;
}
.shuxian2{
	width:1px;
	height:72px;
	background-color: #fff;
	margin:0 26px;
}
.imgone{
	width:240px;
	height:102px;
}
.imgtwo{
	width:210px;
	height:71px;
}
.footers-cont dl dt img {
  height: 100%;
  width:100%;
}
.footers-cont dl dd {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8%;
}
.footers-cont dl dd p {
  color: #555555;
  font-size: 14px;
}
.footers-cont ul li {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10%;
}
.footers-cont ul li:last-child {
  margin-right: 0 !important;
}
.footers-cont ul li img {
  width: 80px;
  margin-bottom: 10px;
}
.footers-cont ul li p {
  color: #555555;
  font-size: 12px;
}
</style>