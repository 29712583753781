<template>
  <div id="app">
    <title>{{ title }}</title>
    <Headers @trilFome="iShowTryDialog" :start="p_source" v-if="p_source == 0 || p_source == null"></Headers>
    <section>
      <router-view></router-view>
      <fixedDialog :moreDivOffsetTop="moreDivOffsetTop" :scrollTop="scrollTop" @trilFome="iShowTryDialog"></fixedDialog>

      <trialFome ref="trialFome" :visible="tryDialog" @updateVisible="closeVisible" @submit="onSubmit"
        @touchmove.prevent @mousewheel.prevent></trialFome>
    </section>
    <Footers :start="start" v-if="p_source == 0 || p_source == null"></Footers>
  </div>
</template>

<script>
import Headers from "./layouts/headers.vue";
import Footers from "./layouts/footers.vue";
import fixedDialog from "@/components/fixedDialog.vue";
import trialFome from "@/components/trialFome.vue";
import { message } from "ant-design-vue";
import { ProductTrialSave } from "@/apis/productTry.js";


export default {
  name: "App",
  components: {
    Headers,
    Footers,
    fixedDialog,
    trialFome
  },
  data() {
    return {
      tryDialog: false,
      newjid: "",
      title:"优数合一 - 优数合一助力安保行业提升服务能力",
      p_source:0,
    }
  },
  // watch: {  
  //   '$route': 'updateMeta', // 监听路由变化  
  // }, 
  created(){
    this.p_source = this.GetUrlString("p_source")
	// this.p_source = this.$route.query.p_source
	// this.$route.query.user
	console.log("this.p_source==",this.p_source)
  },
  mounted() {
   
    if(this.p_source==1){
      this.title= "安易聘-智慧、可信赖的安保行业人力资源服务商";
     document.querySelector('link[rel="icon"]').setAttribute('href', "/anyipin.ico")
    }else if(this.p_source==0 || this.p_source==null){
      this.title= "安易聘-智慧、可信赖的安保行业人力资源服务商";
     document.querySelector('link[rel="icon"]').setAttribute('href', "/favicon.ico")
    }
     
  },
  methods: {
    updateMeta() {  
      // 假设你的路由配置中包含 meta 字段，如 title 和 icon  
      const currentRoute = this.$route;  
      if (currentRoute.meta && currentRoute.meta.title) {  
        this.currentTitle = currentRoute.meta.title;  
      }  
      if (currentRoute.meta && currentRoute.meta.icon) {  
        this.currentIcon = `${process.env.BASE_URL}${currentRoute.meta.icon}`;  
      } else {  
        this.currentIcon = `${process.env.BASE_URL}favicon.ico`; // 默认图标  
      }  
  
      // 更新页面标题  
      document.title = this.currentTitle;  
  
      // 更新图标（如果有的话）  
      let iconLink = document.querySelector('link[rel="icon"]');  
      if (iconLink) {  
        iconLink.setAttribute('href', this.currentIcon);  
      } else {  
        // 如果图标链接不存在，可以创建一个  
        let link = document.createElement('link');  
        link.rel = 'icon';  
        link.href = this.currentIcon;  
        document.head.appendChild(link);  
      }  
    },  
    GetUrlString(name) {
      console.log("安易聘");
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    },
    iShowTryDialog(data) {
      console.log(data)
      this.tryDialog = data;
    },
    closeVisible(data) {
      this.tryDialog = data.tryDialog;
    },
    onSubmit(data) {
		console.log("提交")
      let formState = JSON.parse(JSON.stringify(data));
      ProductTrialSave({
        company: formState.EnterpriseName,
        user: formState.userName,
        phone: formState.userPhone,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 1) {
          message.success(res.data.message);
        }
      });
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}
</style>
