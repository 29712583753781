<template>
  <div class="section_main">
    <div class="section_cont swiper_cont" id="home">
		<div class="banner"></div>
      <!-- <div class="videoContainer"> -->
		  <!-- 注释的 -->
        <!-- <video class="fullscreenVideo" id="bgVid" playsinline="" autoplay="" muted="" loop="">
          <source src="../assets/img/video.mp4" type="video/mp4" />
        </video> -->
		<!-- 注释的 -->
      <!-- </div> -->
      <!-- <div style="width: auto;height: 500px;">1111111111111</div> -->
      <!-- <div class="c-w1200" style="overflow: hidden; position: relative;"> -->
        <!-- <div class="swiper_text">
          <div class="swiper_title">人工智能+安保</div>
          <div class="swiper_title">助力安保行业提升多方面能力</div>
          <div class="swiper_tabs">创新 ｜ 发展 ｜ 共赢 ｜ 未来</div>
        </div> -->
		<!-- 注释的 -->
       <!-- <swiper class="banner-text" v-bind="swiperOptions" :space-between="0">
          <swiper-slide>

            <div class="swiper_text">
              <div class="swiper_title">人工智能+企服</div>
              <div class="swiper_title">优数合一助力传统企业人力资源等数字化转型</div>
            </div>
          </swiper-slide>
          <swiper-slide>

            <div class="swiper_text">
              <div class="swiper_title">人工智能+安保</div>
              <div class="swiper_title">优数合一助力安保行业提升多方面能力</div>
            </div>
          </swiper-slide>
          <swiper-slide>

            <div class="swiper_text">
              <div class="swiper_title">人工智能+政法</div>
              <div class="swiper_title">优数合一助力政法行业提升多方面能力</div>
            </div>
          </swiper-slide>
          <div class="banner-pagination"></div>

        </swiper> -->
		<!-- 注释的 -->
      <!-- </div> -->
    </div>

    <div class="section_cont bgcimg" style="padding-top: 40px;padding-bottom:1px;">
      <div class="c-w1200">
        <h3>平台数据统计</h3>
        <h6 class="anq">
          产品安全稳定可靠 您值得信赖的合作伙伴
        </h6>

        <div class="desc_cont">
          <ul class="desc_ul">
            <li v-for="item in statistics" :key="item.num">
              <div class="desc_name">
                {{ item.num }}
              </div>
              <div class="desc_mm">
                {{ item.name }}

              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>


    <div class="section_cont_one" id="more">
      <div class="c-w1200">
        <h3 class="h_title">三大核心能力</h3>
        <h6 class="anq" style="margin-top: 30px;">
          重拳出招 解决安保用人难问题
        </h6>
		<!-- 之前的 -->
       <!-- <div class="product_box" style="margin-top: 65px;">
          <div>
            <div>
              <img class="specific" src="../assets/homeimg/specific.png" alt="">
            </div>
            <div class="ai_box">
              <div class="ai_right">
                <div class="ai_duih">
                  AI对话助力筛选
                </div>
                <div class="p_csl">
                  <div class="describe">基于最新版GPT大模型长期训练</div>
                  <div class="describe">24小时在线的招聘小助手，高效，快捷</div>
                </div>

              </div>
              <img class="Ai_xa" src="../assets/homeimg/ai_xa.png" alt="">
            </div>
            <div style="margin-top: 40px;">
              <img class="specific" src="../assets/homeimg/wx_home.png" alt="">
            </div>
          </div>
          <div>
            <div>
              <div class="asg">
                <div class="ai_duih" style="  margin-top: 200px;">
                  人工智能生成简历
                </div>
                <div class="p_csl">
                  <div class="describe" style="text-align: left;">专门针对不擅长打字和复杂操作的人群特征设计</div>
                  <div class="describe" style="text-align: left;">1分钟内快速生成简历</div>
                </div>
                <img class="user_img" src="../assets/homeimg/user_t.png" alt="">
              </div>
            </div>
            <div>
              <img class="specific" src="../assets/homeimg/specificthree.png" alt="">
            </div>
            <div>

              <div class="asg" style="     padding-left: 65px;">
                <div class="ai_duih">
                  精准匹配，所见即所得
                </div>
                <div class="p_csl">
                  <div class="describe" style="text-align: left;">十年行业深耕，海量安保行业数据支撑
                  </div>
                  <div class="describe" style="text-align: left;">精准撮合，快速聊天</div>
                </div>
                <img class="mubao_img" src="../assets/homeimg/mubao.png" alt="">
              </div>
            </div>
          </div>
        </div> -->
		<!-- 之前的 -->
		<!-- 现在 -->
		<div class="product_box" style="margin-top: 65px;">
		  <div class="form1">
		    <div>
		      <img class="specific" src="https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/specific.png" alt="">
		    </div>
			<div class="asg">
			  <div class="ai_duih">
			    人工智能助力求职
			  </div>
			  <div class="p_csl">
			    <div class="describe" style="text-align: left;">专门针对不擅长打字和复杂操作的人群特征设计</div>
			    <div class="describe" style="text-align: left;">1分钟内快速生成简历</div>
			  </div>
			  <img class="user_img" src="../assets/homeimg/user_t.png" alt="">
			</div>
		</div>
		<div class="form2">
			<div class="ai_box">
			<div class="ai_right">
			    <div class="ai_duih2">
			      AI对话助力筛选
			    </div>
			    <div class="p_csl">
			      <div class="describe">基于行业自研大模型长期训练</div>
			      <div class="describe">24小时在线的招聘小助手，高效，快捷</div>
			    </div>
			
			  </div>
			  <img class="Ai_xa" src="../assets/homeimg/ai_xa.png" alt="">
			  </div>
			  <div style="margin-right:30px;">
			    <img class="specific" src="https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/specificthree.png" alt="">
			  </div>
			</div>
			
			<div class="form1">
				<div style="margin-top: 40px;">
				  <img class="specific" src="https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/wx_home.png" alt="">
				</div>
				<div class="asg2" style="padding-left: 65px;">
				  <div class="ai_duih">
				    精准匹配，所见即所得
				  </div>
				  <div class="p_csl">
				    <div class="describe" style="text-align: left;">十年行业深耕，海量安保行业数据支撑
				    </div>
				    <div class="describe" style="text-align: left;">精准撮合，快速聊天</div>
				  </div>
				  <img class="mubao_img" src="../assets/homeimg/mubao.png" alt="">
				</div>
			</div>
		</div>
		
		<!-- 现在 -->
      </div>
    </div>

    <div class="section_cont1 " id="customerCon">
      <div class="c-w1200 akal" style="padding: 0 50px 50px 50px;">
        <div style="display: flex; flex-direction: column; align-items: center;justify-content: center;">
          <div class="tit_kh">
            客户案例
          </div>
          <div class="tit_hh">
            以用户价值为导向 共筑安保价值链
          </div>
        </div>

        <div class="ak_xx" @mouseover="imgMouseOver(1)" @mouseleave="imgMouseLeave(1)">
          <img src="https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/gongsi4.png" alt="">
          <div class="mm_lll" v-if="imgstart1">
            <div class="nn_k"> 北京都杰安防科技有限公司</div>
            <div class="mm_kjj"> 都杰先后被公安部评定为人力防范保安服务公司“壹级”企业，被商务部国际贸易经济合作研究院评定为AAA企业，是2019年度北京商务服务业创新和国际化品牌百强企业。</div>
          </div>
          <div class="mm_lll" style="height: 60px;" v-else>
            北京都杰安防科技有限公司
          </div>
        </div>
        <div class="ak_xx_two" @mouseover="imgMouseOver()" @mouseleave="imgMouseLeave()">
          <img src="https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/gongsi.png" alt="">
          <div class="mm_lll" v-if="imgstart">
            <div class="nn_k"> 北京京诚京安保安服务有限公司</div>
            <div class="mm_kjj"> 北京京诚京安保安服务有限公司，前身是北京振远护卫中心直属支队。公司实施改制于2010年10月份作为北京市公安局首批十佳标杆保安企业之一；专业从事保安、安检服务。</div>
          </div>
          <div class="mm_lll" style="height: 60px;" v-else>
            北京京诚京安保安服务有限公司
          </div>
        </div>
      </div>

      <div style="width: 100%;height: 400px;">

      </div>

    </div>

    <div class="section_cont cont my_bgc" id="companyProfileCon" style="position: relative;">
      <div class="c-wtwo">
        <h3>为什么选择我们</h3>
        <h6 style=" margin-bottom: 80px;">针对企业六大痛点逐个击破</h6>

        <img style="margin-right: 100px; " src="https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/myonf.png" alt="">
      </div>
    </div>

    <div class="section_cont cont" id="aboutCon">
      <div class="c-w1200">
        <h3>与众不同的我们</h3>
        <h6 class="fam_aa">AI智能招聘平台</h6>
        <div style="display: flex; justify-content: space-around; margin-top: 100px;">
          <div>
            <div class="mm_l33">优数合一</div>
			<div class="tit_fff">Unity of Optimal and Numerous </div>
            <div class="cons_mm">
             <p>专为安保领域量身定制，通过AI技术革新行业传统模式，提供“入-培-巡-离”一站式人工智能替代传统人力解决方案。</p>
            <p style="margin-top: 10px;">关于安易聘</p>
             <p>安易聘AI+智招平台致力于为三低务工人群解决找工作的核心问题，以不擅长打字和复杂操作的人群特征设计通过AI对话智能生成简历、自动匹配岗位并进行智能人才推荐、提供24小时在线招聘小助手等，科技助老助残助低文凭群体再就业，为求职者和雇主提供无与伦比的招聘效率和个性化服务，解决安保行业用人难痛点。</p>
            </div>
          </div>
          <div class="yzbt">
            <img src="../assets/homeimg/banner_3.png" alt="">
          </div>
        </div>
      </div>

    </div>
    <div class="section_cont cont" id="aboutMyCon">
      <div class="c-w1200">
        <h3>联系我们</h3>
        <div class="section_map">

          <div id="container">

          </div>
		  <div class="dizhi">
			  <p>公司地址：北京市东城区夕照寺街东富瑞大厦5层501</p>
		  </div>
        </div>






      </div>
    </div>
  </div>
  <Footers :start="start" v-if="p_source == 1"></Footers>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import Footers from "../layouts/footers.vue";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
import "swiper/swiper-bundle.css";

import AMapLoader from '@amap/amap-jsapi-loader';

import { shallowRef } from "vue";
const map = shallowRef(null)
// import Amap from '../components/Amap.vue';


export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    Swiper,
	Footers,
    SwiperSlide
  },
  data() {
    return {

p_source:0,
      visible: false,
      isHovering: "",
      isHoveringtwo: "o",
      swiperOptions: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: true,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".banner-pagination",
          clickable: true, //允许分页点击跳转
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        observer: true,
        observeParents: true,
      },
      swipertwoOptions: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: true,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        observer: true,
        observeParents: true,
      },
      arrimg: [
        {
          img: require("../assets/homeimg/banner6.png"),
          id: 2
        },
        {
          img: require("../assets/homeimg/banner4.png"),
          id: 1
        },

        {
          img: require("../assets/homeimg/banner5.png"),
          id: 3
        },

      ],
      // arrimg:["../assets/homeimg/banner4.png","../assets/homeimg/banner4.png","../assets/homeimg/banner4.png"],
      statistics: [
        {
          num: "10w+",
          name: "安易聘注册量"
        },
        {
          num: "100W+",
          name: "全平台粉丝量"
        },
        {
          num: "1000W+",
          name: "全网播放总量"
        },
        {
          num: "3000+",
          name: "全国意向合作项目"
        },
        {
          num: "5000+",
          name: "智能生产预定量"
        },
      ],
      painSpotList: [
        {
          path: require("@/assets/img/icon_001.png"),
          title: "简历多",
          desc: "海量安保求职者",
          desc2: "简历库满足用人单位需求",
          desc3: "",
        },
        {
          path: require("@/assets/img/icon_002.png"),
          title: "无限畅聊",
          desc: "可以对简历库里所有的求",
          desc2: "职者发起聊天，索要电话，微",
          desc3: "信，不限次数",

        },
        {
          path: require("@/assets/img/icon_003.png"),
          title: "AI助力",
          desc: "7*24小时代替企业回复求",
          desc2: "职者问题，主动问求职者企业关心的",
          desc3: "问题，交换电话，微信。",

        },
        {
          path: require("@/assets/img/icon_004.png"),
          title: "人群精准",
          desc: "我们只做安保行业，简历",
          desc2: "精准，简历库中九成以上是",
          desc3: "安保求职者。",
        },
        {
          path: require("@/assets/img/icon_005.png"),
          title: "保证效果",
          desc: "拥有大量社群和管家，每天多轮",
          desc2: "次推荐岗位到社群，前期意向沟通，后期",
          desc3: "撮合入职。保证合作期内招到人。",
        },
        {
          path: require("@/assets/img/icon_006.png"),
          title: "专业",
          desc: "深耕安保行业，一对",
          desc2: "一专业服务和建议，全方位提升",
          desc3: "招聘效率",
        },
      ],
      supportList: [
        {
          name: "优数合一北京总部",
          telephone: "19201144101",
          address: "北京市东城区夕照寺街富瑞大厦",
        },
        {
          name: "客服支持",
          telephone: "19201144101",
          email: "kefu@ushuy.com",
        },
        {
          name: "商务合作",
          telephone: "19201144101",
          email: "cooperation@ushuy.com",
        },
      ],
      customerList: [
        {
          id: 1,
          name: "北京都杰安防科技有限公司",
          desc: "都杰先后被公安部评定为人力防范保安服务公司“壹级”企业，被商务部国际贸易经济合作研究院评定为AAA企业，是2019年度北京商务服务业创新和国际化品牌百强企业。",
        },
        {
          id: 2,
          name: "北京京诚京安保安服务有限公司",
          desc: "北京京诚京安保安服务有限公司，前身是北京振远护卫中心直属支队。公司实施改制于2010年10月份作为北京市公安局首批十佳标杆保安企业之一；专业从事保安、安检服务。",
        },
        {
          id: 3,
          name: "中城保安（北京）有限公司",
          desc: "中城保安（北京）有限公司前身系中军军弘安保集团北京分公司。由职业经理人、退转军警和行业专家组成，培养多名保安职业经理人和保安管理师。合作单位300余家，安保人员近1000人。",
        },
        {
          id: 4,
          name: "北京老兵京保保安服务有限公司",
          desc: "北京老兵京保保安服务有限公司成立于2004年06月10日，注册地位于北京市顺义区仁和地区顺通路38号。在公司发展壮大的18年里，我们始终为客户提供好的产品和技术支持、健全的售后服务。",
        },
        {
          id: 5,
          name: "卫华(北京)保安服务有限公司",
          desc: "卫华(北京)保安服务有限公司成立于2021年08月25日，注册地位于北京市顺义区林河南大街33号。公司主要经营包括门卫、巡逻、守护;劳务分包;机动车公共停车场管理;物业管理;绿化管理;建筑物清洁服务：清洁服务等",
        },
      ],
      activeSlide: 0,
      scrollTop: 0,
      imgstart: false,
      imgstart1: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      videoShow: false,
      img_src: "",
      seccontent: "在当今快速变化的商业环境中，安保行业面临着日益庞大且复杂的人才招聘任务。随着业务的扩张和市场的波动，人员的需求也呈现出高频的变动趋势。这意味着人力资源部门必须不断调整招聘策略，以适应不断变化的岗位需求。不仅要确保新招聘的员工能够迅速填补岗位空缺，同时还要应对因业务调整、员工离职或其他因素导致的职位变动。这种高频变动对招聘流程的效率和灵活性提出了更高的要求，人力资源团队需要不断创新和优化招聘流程，以确保能够及时、高效地满足企业的人才需求。这包括但不限于改进候选人筛选机制、加强招聘渠道的管理、提高面试效率以及完善员工入职和培训流程等。",
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
      new swiper(".swiper-container", {
        slidesPerView: "auto",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      });
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
	
    //初始化地图方法
    const initMap = () => {
      console.log("chushihua1222", map.value)
      // let that = this;
      AMapLoader.load({
        key: "dd4cc5f831b67cde767de9a65f983650",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        map.value = new AMap.Map("container", {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 17,           //初始化地图级别 2-20
          center: [116.440747, 39.88887], //初始化地图中心点位置
        });
        new AMap.Marker({
          position: [116.440747, 39.88887],
          map: map.value
        });
        // console.log("map.value==", map.value)
      }).catch(e => {
        console.log(e);
      })
    };

    return {
      onSwiper,
      initMap,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  watch: {
    $route: {
      handler(val, oldval) {
        console.log(val); //新路由信息
        console.log(oldval); //老路由信息
        console.log(val.hash.substring(1));
        if (document.getElementById(val.hash.substring(1))) {
          document.getElementById(val.hash.substring(1)).scrollIntoView({
            behavior: "smooth", //平滑滚动
          });
        }
        if (this.scrollTop == 0) {
          this.$router.push('/')
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {

    imgMouseOver(data) {
      console.log(data);
      if (data) {
        this.imgstart1 = true;

      } else {
        this.imgstart = true;

      }

    },
    imgMouseLeave(data) {
      if (data) {
        this.imgstart1 = false;

      } else {
        this.imgstart = false;

      }
    },
    handleMouseOver(e) {
      console.log(111, e);
      this.isHoveringtwo = e;

    },
	GetUrlString(name) {
	  console.log("安易聘");
	  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	  var r = window.location.search.substr(1).match(reg);
	  if (r != null) return unescape(r[2]); return null;
	},
    avv(e) {
      // console.log("3333", e);
      this.isHovering = e;
    },
    handleMouseLeave(e) {
      console.log(1222, e);
      this.isHoveringtwo = "";
    },
    gotoDetails(Id) {
      this.$router.push("/customer/" + Id);
    },
    videoBb(a) {

      if (a == "anbaohei") {
        this.img_src = "https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/video/video_01.mp4"

      }
      if (a == "jiemi") {
        this.img_src = "https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/video/video_02.mp4"

      }
      if (a == "zhaopin") {
        this.img_src = "https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/video/video_03.mp4"

      }
      this.videoShow = true;
    },
    onClone() {
      this.videoShow = false;
    },
    gotoProductDetails(Id) {
      this.$router.push("/product/" + Id);
    },
    onsecel(val) {
      if (val == 1) {
        this.seccontent = "在当今快速变化的商业环境中，安保行业面临着日益庞大且复杂的人才招聘任务。随着业务的扩张和市场的波动，人员的需求也呈现出高频的变动趋势。这意味着人力资源部门必须不断调整招聘策略，以适应不断变化的岗位需求。不仅要确保新招聘的员工能够迅速填补岗位空缺，同时还要应对因业务调整、员工离职或其他因素导致的职位变动。这种高频变动对招聘流程的效率和灵活性提出了更高的要求，人力资源团队需要不断创新和优化招聘流程，以确保能够及时、高效地满足企业的人才需求。这包括但不限于改进候选人筛选机制、加强招聘渠道的管理、提高面试效率以及完善员工入职和培训流程等。"
      } else if (val == 2) {
        this.seccontent = "在现代社会，尽管互联网技术已经深入到我们生活的方方面面，但在某些行业中，传统的劳务模式仍然未能实现互联网化。尤其在人力资源管理方面，许多安保企业仍然沿用着传统的管理模式，导致管理效率低下，效果不尽如人意。这些企业在人员招聘、培训、考核等方面，往往依赖于纸质文件和手工报表，不仅效率低下，而且容易出错。此外，由于缺乏专业的人力资源管理知识和技能，这些企业在人力资源管理的各个环节都存在不少问题。例如，在招聘过程中，由于缺乏有效的招聘渠道和筛选机制，往往难以吸引到优秀的人才；在培训环节，由于缺乏系统性的培训体系和专业的培训人员，员工的技能提升受到限制；在薪酬福利管理方面，由于缺乏合理的薪酬体系和福利制度，员工的积极性和满意度也难以得到有效提升。因此，企业需要加强对人力资源管理的投入，引进先进的信息技术和管理理念，提升管理的专业性和效率，以更好地应对市场竞争。"
      } else if (val == 3) {
        this.seccontent = "在安保行业招聘过程中，有些岗位对候选人的技能和经验要求相对较低，理论上，这些岗位应该能够快速吸引到符合基本要求的候选人，招聘过程应该相对简单和高效。然而，实际情况却往往并非如此。由于岗位要求低，这些岗位往往会吸引大量的求职者，包括那些并不完全符合岗位要求的人。这就给企业的人力资源部门带来了巨大的挑战。首先，在筛选简历阶段，招聘团队需要花费大量时间浏览和筛选大量的简历，从中挑选出符合岗位要求的候选人。这个过程往往需要招聘团队具备较高的专业素养和经验，以便准确判断候选人的潜力和适合度。接着，在组织面试阶段，招聘团队需要面对的是数量庞大的候选人。这意味着他们需要投入大量的时间和精力来安排面试时间、准备面试材料以及进行面试。面试成本也随之水涨船高，无论是从时间成本、人力成本还是其他资源成本来看，企业都需要付出更多的代价来找到合适的人选。此外，由于岗位要求低，候选人之间的差异可能并不明显，这给招聘团队在评估和比较候选人方面带来了更大的挑战。他们需要更加细致和精准地评估每个候选人的能力和潜力，以确保最终选出的候选人能够胜任岗位并为企业带来价值。"
      }

    },
    scrollEvent($event) {
      console.log($event);
    },
    handleScroll() {
		// console.log("dedd")
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
  },
  created() {
    this.p_source = this.GetUrlString("p_source")
  },
  mounted() {
    let moreDivOffsetTop = document.getElementById("more").offsetTop;
    this.moreDivOffsetTop = moreDivOffsetTop;
    window.addEventListener("scroll", this.handleScroll);
    this.initMap()

  },
};
</script>

<style scoped>
@import url("../assets/css/common.css");
#aboutMyCon{
	padding-top:100px;
}
.mm_kjj {

  line-height: 15px;

  font-size: 14px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}

.mm_lll {
  width: 100%;
  position: absolute;
  /* text-align: center;
  line-height: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  bottom: 0;
  left: 0;
  height: 160px;
  padding: 20px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
}

.section_cont_one {
  margin: 100px 0;
  text-align: center;
}

.ak_xx {
/*  width: 506px;
  height: 340px; */
  width: 30%;
  height:60%;
  position: absolute;
  top: 70%;
  left: 17%;

  border: 0.5px solid rgba(0, 0, 0, 0.2);

}

.bgc_huu {

  width: 100%;
  height: 200px;
  position: absolute;
  top: 34%;
  left: 0;
  background: #D8D8D8;
}

.tit_fff {
	margin-top: 20px;
  font-size: 14px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}

.cons_mm {
  /* text-indent: 2em; */
  margin-top: 18px;
  width: 600px;
  font-size: 15px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 25px;
  text-align: justify;
}

.mm_l33 {
  font-size: 36px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
  text-align: justify;
}

.fam_aa {


  font-size: 12px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 3px;
}

.ak_xx .mm_lll .nn_k {
  font-size: 20px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: #0071F5;
  margin-bottom: 20px;
}

.ak_xx_two .mm_lll .nn_k {
  font-size: 20px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: #0071F5;
  margin-bottom: 20px;
}

.ak_xx_two {
 /* width: 506px;
  height: 340px; */
width: 30%;
  height:60%;
  position: absolute;
  top: 70%;
  right: 17%;

  border: 0.5px solid rgba(0, 0, 0, 0.2);
  background: #fff;
}

/* .ak_xx_two:hover .mm_lll {} */

.ak_xx_two img {
  width: 100%;
  height: 100%;
}

.ak_xx img {
  width: 100%;
  height: 100%;
}

.tit_kh {


  margin-bottom: 30px;
  font-size: 60px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: 6px;
}

.tit_hh {
  font-size: 20px;
  font-family: "苹方";
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 3px;
}

.carousel {
  position: relative;
}

.akal {
  position: relative;
  width: 100%;
  height: 500px;
  background: url(https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/bgc.png);
  background-size: 100%;
}

.section_map {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amapcss {
  width: 100%;
  height: 100%;
}

#container {
  width: 70%;
  height: 70%;

}
.section_map{
	display:flex;
	flex-direction: column;
}
.dizhi{
	width: 70%;
	height:50px;
	display:flex;
	justify-content: center;
	align-items: center;
	background-color: #f7f9fc;
}
.dizhi p{
	font-size: 14px;
	font-family: "苹方";
	font-weight: 400;
	color: rgba(0,0,0,0.6);
}
.popover_c {
  /* height: 55.5px; */



  font-size: 14px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.popover_w {
  /* height: 10px; */

  padding: 14px 0;
  font-size: 14px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.popover_t {

  height: 22.5px;



  font-size: 22px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 700;
  color: #0071F4;
}

.popover {
  padding: 20px 10px;
  width: 280px;
  height: 200px;
  top: 33%;
  left: 0;
  z-index: 100000000000000;
  position: absolute;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  background: #fff;
  border-left: 4px solid #0071F4;
}

.banner-text {
  position: relative;
}

.banner-text :deep(.swiper-slide) {
  width: 100% !important;
}

.banner_ayp {
  /* width: 300px; */
  /* height: 300px; */
  background: #fff;
}

.ys_box {
  padding: 40px 30px;
  background-color: #fff;
}

.foot_ys {
  margin-top: 40px;


  font-size: 12px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}

.title_ys {
  /* width: 71px; */
  height: 33.5px;
  top: 1056.5px;
  left: 277px;


  font-size: 24px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  text-align: justify;
}

.banner-pagination {
  position: absolute;
  bottom: 30px;
  left: 100%;
  z-index: 9;
  transform: translateX(-50%, -50%);
}

:deep(.el-carousel__indicators) {

  /* left: unset;
  transform: unset;
  right: 2%; */
}

:deep(.el-carousel__button) {

  /* width: 10px; */
  height: 4px;
  /* border: none; */
  /* border-radius: 50%; */
  /* background-color: rgba(0, 0, 0, 0.2); */
}

:deep(.is-active .el-carousel__button) {

  background: #3f8ec8;
}


:deep(.el-carousel__item) {
  background-color: #fff !important;
}

:deep(.el-carousel__indicators--outside) {
  margin-top: 30px;
  /* background-color: #0071F5 !important; */
}

:deep(.is-active) {
  /* lass="el-carousel__indicator el-carousel__indicator--horizontal is-active" */

  /* background-color: #0071F5 !important; */
}

.banner-pagination :deep(.swiper-pagination-bullet) {
  margin: 0 5px;
  width: 12px;
  height: 12px;
  background: #fff;
}

.assse {
  font-size: 18px;
  text-indent: 2em;
  line-height: 30px;
}

.Ai_xa {
  width:200px;
  height:200px;
  transform: rotate(3deg);
margin-top:60px;
margin-left:260px;
}

.ai_duih {
  font-size: 48px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 700;
  color: #167DF5;
  text-align: justify;
}
.ai_duih2{
	font-size: 48px;
	font-family: "阿里巴巴普惠体 2.0";
	font-weight: 700;
	color: #167DF5;
	width:100%;
	text-align: right;
}
.product_box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.form1{
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items:center;
}
.form2{
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}
.anq {
  font-size: 20px;
  font-family: "苹方";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 6px;
}
.yzbt{
	width:380px;
	height:307px;
}
.yzbt img{
	width:100%;
	height:100%;
	border-radius: 10px;
}
.ai_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-right:209px;
  margin-top:-60px;
}

.p_csl {
  margin-top: 36px;
  font-size: 32px;
}

.ai_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.describe {
  font-size: 28px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 48px;
  text-align: right;
}

.specific {
  width: 382px;
  height: 640px;




  /* box-shadow: 0px 0px 10px 0px rgba(22, 125, 245, 0.3); */
}

.desc_ul {
  display: flex;
  justify-content: space-around;
}

.ys_jn {



  font-size: 30px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
  letter-spacing: 6px;
}

.desc_name {


  margin-bottom: 20px;
  font-size: 48px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 800;
  color: #0071F5;
  text-align: center;
}

.desc_mm {
  font-size: 24px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.cont {
  background: #f7f9fc;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  /* 视口宽度的100% */
  /* height: 958px; */
  min-height: 100vh;
  /* padding-bottom:100px; */
  /*
  
}
.section_cont:nth-child(even) {
  background: #fff;
  /* height: 100vh; */
}

.section_cont:nth-child(odd) {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom:50px;
}

.c-h-500 {
  color: #000;
  text-indent: 2em;
  line-height: 30px;
  font-size: 18px;

}

.c-h-500 text {
  color: #000;
}

/* 基础描述 */
.banner{
	background-image: url("https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/beijing.png");
	background-repeat: no-repeat !important;
	width: 100%;
	/* height: 100vh; */
	height: 780px;
	/* margin-top:98px; */
	background-size:100% 100%;
	/* background-size:cover; */
}
@media (max-width:1550px) {
  .banner{
   height: 700px;
   background-size: cover;
  }
}
.swiper_cont {
  background: url("../assets/img/video.mp4") no-repeat !important;
  background-size: 100% !important;

}

.awiper_img {
  width: 100%;
  height: 100vh;
}

.videoContainer {
  position: absolute;
  width: 100%;
  height: 565px;
  overflow: hidden;
  background: url("../assets/homeimg/beijing.png") no-repeat !important;
  background-size: 100% !important;
  z-index: -100;
}


/* .videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  bottom: 0;
  left: 0;
  background: rgba(25, 29, 34, 1);
} */

:deep(.swiper-pagination) {
  bottom: -40px !important;
  height: 10px !important;
  width: 100% !important;
  z-index: 9999 !important;
}

video {
  position: absolute;
  width: 100%;
  display: block;
  z-index: -1;
  bottom: -20%;
  left: 0;
  right: 0;
}

.swiper_text {
  padding-top: 150px;
  padding-bottom: 200px;
}

.swiper_title {
  font-size: 48px;
  color: #fff;
}

.swiper_tabs {
  margin-top: 120px;
  color: #fff;
  font-size: 22px;

}

.swiper_title:nth-child(1) {
  padding-bottom: 20px;
}

.section_cont h3 {
  font-size: 60px;
  /* width: 285px; */

  letter-spacing: 12px;
  font-weight: bold;
  text-align: center;
  /* padding-top: 5%; */
  /* padding-bottom: 2%; */
}

.section_cont h6 {
  /* font-size: 16px; */
  text-align: center;
  margin-top: 30px;
font-size: 20px;
    font-family: "苹方";
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 6px;
}

.desc_cont {
  padding-top: 70px;
  padding-bottom: 200px;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  text-indent: 2em;

}

.desc_cont p {
  font-size: 18px;

  padding-bottom: 15px;
}

/* 核心 */
.core_cont {
  padding-top: 35px;


}

.bgcimg {
	/* background-image: url("../assets/homeimg/shujutu.png"); */
	background-image: url("https://ushuy-security-prod.obs.cn-north-4.myhuaweicloud.com/ushu-web/img/echartimg.png"); 
	
	
	background-repeat: no-repeat !important;
	background-size: 100% 95%;
	height:650px;
}

.my_bgc {}

.h_title {
  font-size: 60px;
  font-family: "阿里巴巴普惠体 2.0";
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
  letter-spacing: 12px;
}

.core_cont dl {
  display: flex;
  align-content: center;
  justify-content: space-between;

}

.mubao_img {
  width: 320px;
  height: 320px;
margin-top: 30px;
}

.core_cont dl dt {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_img {
  width: 420px;
  height: 299px;

  margin-right: 140px;
  margin-top: 30px;
}

.asg {
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-top: 70px;
}
@media (max-width: 1366px){
    .asg {
        margin-left: 40px;
    }
	.asg2 {
	    margin-left: 40px;
	}
	.ai_box {
	    margin-right: 80px;
	}
}
.asg2 {
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-top: 120px;
}
.core_cont dl dt img {
  height: 100px;
}

.core_cont dl dd {
  width: 80%;
  position: relative;
}

.core_cont dl dd h4 {
  font-size: 22px;
  /* color: #555555; */
  color: #000;
  margin-bottom: 20px;
  line-height: 22px;
}

.core_cont dl dd h5 {
  font-size: 16px;
  color: #555555;
  line-height: 35px;
}

.core_btn {
  position: absolute;
  right: 0;
}

.core_btn .ant-btn-primary {
  background: #3f72fd;
}

/* 更多 */
.more_cont {
  padding-top: 80px;
  /* padding-bottom: 100px; */
  width: 100%;
}

.more_cont ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8%;
  margin-bottom: 40px;
}

.more_cont ul li {
  /* padding: 90px  0!important; */
  padding-top: 70px;
  position: relative;
  margin-right: 30px;
  width: 30%;
  height: 186px;
  /* padding: 4%; */
  list-style: none;
  text-align: center;
  /* display: flex; */
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  line-height: 36px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.more_cont ul li h4 {
  font-size: 18px;
  color: #555555;
}

.more_cont ul li img {
  position: absolute;
  left: 0;
  top: -45px;
  width: 132px;
  height: 132px;
  margin-bottom: 30px;
}

/* 客户案例 */
.case_cont {
  padding-top: 90px;
  padding-bottom: 7%;
  overflow: hidden;
}

.case_cont:deep(.swiper-wrapper) {
  z-index: 0 !important;
  width: auto !important;
}

.case_cont .swiper-container {
  position: unset !important;
}

.case_cont .swiper-container .swiper-slide {
  width: 31.33% !important;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  float: left;
  padding: 4% 2% 3% 2%;
  box-sizing: border-box;
  margin-right: 2%;
}

.case_cont .swiper-container .swiper-slide h4 {
  font-size: 18px;
  color: #555555;
  padding-bottom: 20px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.case_cont .swiper-container .swiper-slide p {
  height: 155px;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  /*autoprefixer: off;*/
  /*autoprefixer: on;*/
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

/* 痛点 */
.spot_cont {
  padding: 90px 80px 0 80px;
  overflow: hidden;
}

.Pain-spot {
  width: 33%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.Pain-spot img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.Pain-spot h4 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bold;
}

.Pain-spot p {
  font-size: 16px;
  color: #555555;
  line-height: 25px;
}

/* 科技技术支持 */
.support_cont {
  padding-top: 90px;
  padding-bottom: 130px;
  overflow: hidden;
}

.support_cont h2 {
  font-size: 18px;
  padding-bottom: 35px;
  line-height: 30px;
  text-indent: 2em;
}

.support_cont ol {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support_cont ol li {
  width: 32%;
  background: #ffffff;
  border-radius: 10px;
  padding: 4% 3% 4% 3%;
}

.support_cont ol li h4 {
  font-size: 22px;
  color: #555555;
  padding-bottom: 28px;
}

.support_cont ol li p {
  font-size: 16px;
  color: #333333;
}

.support_cont ol li p.telephone {
  padding-bottom: 20px;
}



.swiper-pagination :deep(.swiper-pagination-bullet) {
  width: 30px !important;
  height: 10px !important;
  background: rgba(63, 114, 253, 0.3) !important;
  border-radius: 3px !important;
  margin-left: 10px !important;
}

.swiper-pagination :deep(.swiper-pagination-bullet-active) {
  width: 30px !important;
  height: 10px !important;
  background: #3f72fd !important;
  border-radius: 3px !important;
  margin-left: 10px !important;
}

.source_dy {
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.Cases {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin-top: 30px;
}

.Cases_bor {
  text-align: center;
  font-size: 16px;
  margin-top: 4%;
  margin-bottom: 2%;
}

.ba_link {
  display: flex;
}

.ba_link div {
  margin-top: 30px;
  flex: 1;
  text-align: center;
  font-size: 20px;
  color: #3f72fd;
}

.ba_link div text {
  border-bottom: 1px solid #3f72fd;
  padding-bottom: 10px;
}

.clone {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-top: 50px;
  margin-right: 50px;
  width: 30px;
  height: 30px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  z-index: 10010;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.c-w1200 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  max-width: 100%
}
.c-wtwo {
    width: 1400px;
    margin: 0 auto;
}

@media (max-width:1440px) {
  .c-wtwo{
           width: 1200px;
  }
}

</style>
